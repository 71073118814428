import React, { useState } from "react";
import cn from "classnames";
import { Disclosure } from "@headlessui/react";
export default function FaQ({ data }) {
  return (
    <div>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-gray-500 divide-y-1">
          <div
            className="prose text-center dark:prose-dark max-w-none"
            dangerouslySetInnerHTML={{ __html: data?.primary?.header?.html }}
          ></div>

          <div className="mt-6 space-y-6 divide-y divide-gray-500/20">
            {data?.items?.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function Item({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="pt-6">
      <Disclosure>
        {({ open }) => {
          return (
            <>
              <Disclosure.Button as="div">
                <button
                  type="button"
                  className="flex items-start justify-between w-full text-left text-gray-500"
                  aria-controls="faq-0"
                  aria-expanded="false"
                >
                  <div
                    className="prose dark:prose-dark max-w-none"
                    dangerouslySetInnerHTML={{ __html: item?.title?.html }}
                  ></div>
                  <span className="flex items-center ml-6 h-7">
                    <svg
                      className={cn("w-6 h-6 transform rotate-0", {
                        "rotate-0": !open,
                        "-rotate-180": open,
                      })}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                </button>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="pr-12 mt-2" id="faq-0">
                  <div
                    className="prose max-w-none dark:prose-dark"
                    dangerouslySetInnerHTML={{ __html: item?.content?.html }}
                  ></div>
                </div>
              </Disclosure.Panel>
            </>
          );
        }}
      </Disclosure>
    </div>
  );
}
