import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { useMutation } from "react-query";
import { useDropzone } from "react-dropzone";
import { MdClose } from "react-icons/md";
import slugify from "@sindresorhus/slugify";
import { v4 as uuid } from "uuid";
export default function JobsApplication({ title }) {
  const { register, handleSubmit, watch, setValue } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const onDrop = useCallback(([file]) => {
    var reader = new FileReader();
    reader.addEventListener("load", function () {
      setSelectedFile(reader.result);
    });
    reader.readAsDataURL(file);
    setValue("foto", file);
    console.log(file);
  }, []);
  useEffect(() => {
    register("foto", { required: true });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: ["image/jpeg", "image/png"],
    maxSize: 1024 * 1024 * 5,
    multiple: false,
  });
  const send = useMutation(async (values) => {
    const prefix = uuid();
    const filename = encodeURIComponent(
      `${prefix}_${slugify(values.foto.name)}`
    );
    const publicFileURL = `https://storage.googleapis.com/top10-jobs/${filename}`;
    const uploadSign = await fetch("/api/upload-file", {
      method: "POST",
      body: JSON.stringify({ file: filename }),
    }).then((res) => res.json());

    // UPLOAD FILE TO BUCKET
    const url = uploadSign.response;

    const upload = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": values.foto["type"],
        "Access-Control-Allow-Origin": "*",
      },
      body: values.foto,
    }).catch((error) => {
      console.log(error);
    });

    if (!upload.ok) {
      throw "Upload failed";
    }

    return fetch("/api/send-job-email", {
      method: "POST",
      body: JSON.stringify({
        ...values,
        foto: publicFileURL,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== "success") {
          throw "Etwas ist schief gelaufen! Bitte versuche es erneut.";
        }
        return res;
      });
  });
  return (
    <div className="px-4 py-16 overflow-hidden sm:px-6 lg:px-8 lg:py-24 dark:text-black">
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-black dark:text-white sm:text-4xl">
            {title}
          </h2>
        </div>
        <div className="mt-12">
          {!send.data && (
            <form
              onSubmit={handleSubmit(async (values) => {
                await send.mutateAsync(values);
              })}
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div className="sm:col-span-2">
                <label
                  htmlFor="full_name"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Vor- und Nachname
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    {...register("full_name")}
                    name="full_name"
                    id="full_name"
                    autoComplete="organization"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  E-Mail Adresse*
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    {...register("email")}
                    type="email"
                    required
                    autoComplete="email"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="ruckrufnummer"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Rückrufnummer
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="ruckrufnummer"
                    {...register("ruckrufnummer")}
                    id="ruckrufnummer"
                    autoComplete="tel"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="instagram_profile"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Instagram Profil Link
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="instagram_profile"
                    {...register("instagram_profile")}
                    id="instagram_profile"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="auswahlen"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Bitte Auswählen*
                </label>
                <div className="mt-1">
                  <select
                    required
                    name="auswahlen"
                    {...register("auswahlen")}
                    placeholder="---"
                    id="auswahlen"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  >
                    <option disabled selected value={null}></option>
                    {[
                      "Thekenkräfte",
                      "Lightjockeys",
                      "Kellner",
                      "Cocktailmixer",
                      "Lagerkräfte",
                      "Kassenpersonal",
                      "Garderobenpersonal",
                      "Animateure",
                      "Promotionmitarbeiter",
                      "Picshooter",
                      "Deko-Team Mitarbeiter",
                      "VIP-Service",
                    ].map((item) => {
                      return (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Deine Nachricht an uns
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    {...register("message")}
                    rows={4}
                    className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Foto
                </label>
                <div
                  className="block w-full px-4 py-3 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <p className="block text-sm font-medium text-gray-700 ">
                    Hochladen
                  </p>
                </div>
                {selectedFile && (
                  <div className="my-4 ">
                    <div className="relative p-4 bg-white rounded-md w-52">
                      {" "}
                      {/* <div className="absolute top-0 text-black dark:text-white -right-8">
                        <MdClose className="w-6 h-6" />
                      </div>{" "} */}
                      <img src={selectedFile} className="w-full" />
                    </div>
                  </div>
                )}
              </div>

              <div className="sm:col-span-2">
                <button
                  disabled={send.isLoading}
                  type="submit"
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-orange hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                >
                  Senden{" "}
                  {send.isLoading && (
                    <FaSpinner className="ml-2 animate-spin" />
                  )}
                </button>
                {send.error && (
                  <div className="mt-2 font-semibold text-center text-red">
                    {String(send.error)}
                  </div>
                )}
              </div>
            </form>
          )}
          {send.data && (
            <div className="mt-2 font-semibold prose text-center max-w-none dark:prose-dark">
              <h2>Vielen Dank für deine Nachricht.</h2>
              <h3>Wir melden uns so bald wie möglich bei dir.</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Success() {
  return (
    <div className="prose dark:prose-dark">
      <p>
        {" "}
        Vielen Dank für deine Nachricht. Wir melden uns so bald wie möglich bei
        dir.
      </p>
    </div>
  );
}
