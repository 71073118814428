import React from "react";
import { useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { useMutation } from "react-query";
export default function Kontakt({ title }) {
  const { register, handleSubmit } = useForm();
  const send = useMutation(async (values) => {
    return fetch("/api/send-email", {
      method: "POST",
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== "success") {
          throw "Etwas ist schief gelaufen! Bitte versuche es erneut.";
        }
        return res;
      });
  });
  return (
    <div className="px-4 py-16 overflow-hidden sm:px-6 lg:px-8 lg:py-24 dark:text-black">
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-black dark:text-white sm:text-4xl">
            {title}
          </h2>
        </div>
        <div className="mt-12">
          {!send.data && (
            <form
              onSubmit={handleSubmit(async (values) => {
                await send.mutateAsync(values);
              })}
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div className="sm:col-span-2">
                <label
                  htmlFor="full_name"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Vor- und Nachname
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    {...register("full_name")}
                    name="full_name"
                    id="full_name"
                    autoComplete="organization"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  E-Mail Adresse*
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    {...register("email")}
                    type="email"
                    required
                    autoComplete="email"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="ruckrufnummer"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Rückrufnummer
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="ruckrufnummer"
                    {...register("ruckrufnummer")}
                    id="ruckrufnummer"
                    autoComplete="tel"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="auswahlen"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Bitte Auswählen*
                </label>
                <div className="mt-1">
                  <select
                    required
                    name="auswahlen"
                    {...register("auswahlen")}
                    placeholder="---"
                    id="auswahlen"
                    className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  >
                    <option disabled selected value={null}></option>

                    <option value="Events">Events</option>
                    <option value="Öffnungszeiten">Öffnungszeiten</option>
                    <option value="Jobs und Karriere">Jobs und Karriere</option>
                    <option value="Sonstiges">Sonstiges</option>
                  </select>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 dark:text-orange"
                >
                  Deine Nachricht an uns
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    {...register("message")}
                    rows={4}
                    className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                    defaultValue={""}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <button
                  disabled={send.isLoading}
                  type="submit"
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-orange hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
                >
                  Senden{" "}
                  {send.isLoading && (
                    <FaSpinner className="ml-2 animate-spin" />
                  )}
                </button>
                {send.error && (
                  <div className="mt-2 font-semibold text-center text-red">
                    {String(send.error)}
                  </div>
                )}
              </div>
            </form>
          )}
          {send.data && (
            <div className="mt-2 font-semibold prose text-center max-w-none dark:prose-dark">
              <h2>Vielen Dank für deine Nachricht.</h2>
              <h3>Wir melden uns so bald wie möglich bei dir.</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Success() {
  return (
    <div className="prose dark:prose-dark">
      <p>
        {" "}
        Vielen Dank für deine Nachricht. Wir melden uns so bald wie möglich bei
        dir.
      </p>
    </div>
  );
}
